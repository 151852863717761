import * as diff from 'diff';
import type { Change } from 'diff';

const BANNER_STYLES =
  'position:fixed;top:0;left:0;width:90%;background:rgba(0, 0, 0,80%);color:#fff;padding:10px 20px;margin:5%;border-radius:20px;text-align:center;z-index:9999;box-shadow:0 2px 20px rgba(0,0,0,0.8);';

const CLOSE_BUTTON_STYLES =
  'position:absolute;right:20px;top:30px;transform:translateY(-50%);background:none;border:none;color:#fff;font-size:50px;line-height:1;cursor:pointer;';

const TITLE_STYLES = 'text-align:center;padding:6px;font-size:20px;font-weight:bold;';

const CONTENT_STYLES =
  'display:flex;flex-wrap:wrap;max-height:50vh;overflow:auto;padding:20px;background:#fff;margin-top:10px;border-radius:8px;box-shadow:0 4px 40px rgba(0,0,0,0.1);font-family:monospace;white-space:pre-wrap;text-align:left;';

const PANEL_STYLES =
  'flex:1;min-width:0;padding:10px;border-radius:4px;background:#f7f7f7;overflow:auto;border:1px solid #e0e0e0;margin-bottom:10px;color:black;';

const HYDRATION_WARNING_PATTERNS = [
  'Warning: Expected server HTML to contain a matching',
  'Warning: Text content did not match',
  'Warning: Expected server HTML to contain',
  'Warning: Did not expect server HTML to contain',
];
const COLLAPSE_MESSAGE = '... skipped ...';

let banner: HTMLDivElement | null = null;
let content: HTMLDivElement | null = null;
let title: HTMLDivElement | null = null;
let loadingIndicator: HTMLDivElement | null = null;

type ConsoleArgument = string | Error | object | undefined | null;
const formatHydrationArgs = (args: ConsoleArgument[]): string[] => {
  return args.map((arg, index) => {
    if (typeof arg === 'string' && index === 0) {
      let argIndex = 1;
      return arg.replace(/%s/g, () => {
        const nextArg = args[argIndex++];
        return typeof nextArg === 'string' ? nextArg : '';
      });
    }
    if (typeof arg === 'object' && arg !== null) return '';
    return arg || '';
  });
};

// Escape HTML content while preserving highlight tags
function escapeHTML(html: string) {
  return html
    .replace(/&/g, '&amp;') // Escape ampersand first to prevent double encoding
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/'/g, '&#039;')
    .replace(/!highlight style="([^]*?)"!([^]*?)!\/highlight!/g, '<span style="$1">$2</span>'); // Highlight
}

function prettifyHtml(element: string) {
  const rawHtml = element;
  // Split the HTML by '>' and then add it back immediately to each line, except the very last one
  const lines = rawHtml.split('>').map((line, index, array) => line + (index < array.length - 1 ? '>' : ''));
  let formattedHtml = '';
  let indentLevel = 0;

  lines.forEach((line) => {
    // If the line is a closing tag, reduce the indentation
    if (line.startsWith('</')) {
      indentLevel = Math.max(indentLevel - 1, 0); // Prevent negative indentation
    }

    // Apply indentation and add the line break
    formattedHtml += '  '.repeat(indentLevel) + line.trim() + '\n';

    // If the line is an opening tag that is not self-closing, increase the indentation
    if (line.startsWith('<') && !line.startsWith('</') && !line.endsWith('/>')) {
      indentLevel++;
    }
  });

  // Remove the xmlns attribute from the string
  formattedHtml = formattedHtml.replace(/\s+xmlns="[^"]+"/, '');
  // Trim the last newline character added to the string
  return formattedHtml.trim();
}

// Cleans HTML by removing Emotion style tags, extracting <body>, and removing <footer>
function cleanHtml(html = '') {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = html;

  // Extract only the #rendered-content div
  const renderedContent = tempDiv.querySelector('#rendered-content');
  if (!renderedContent) return ''; // Fallback if <div id="rendered-content"> doesn't exist

  // Remove <footer> elements inside <body>
  renderedContent.querySelectorAll('footer').forEach((footer) => footer.remove());

  // Remove Emotion style tags without modifying innerHTML directly
  renderedContent.querySelectorAll('style[data-emotion]').forEach((styleTag) => styleTag.remove());
  return renderedContent.innerHTML.trim();
}

async function highlightDifferences(serverElement: string | null, clientElement: string | null) {
  if (!serverElement || !clientElement) return { serverElement, clientElement };

  function wrapDiff(text: string, color: string) {
    return `!highlight style="background-color:${color};"!${text}!/highlight!`;
  }

  async function computeDiff(serverStr: string, clientStr: string) {
    // Create a promise that rejects in 10 seconds
    const timeoutPromise = new Promise<Change[]>((resolve, reject) => {
      const timeoutId = setTimeout(() => {
        clearTimeout(timeoutId);
        reject(new Error('Diff computation timed out after 10 seconds'));
      }, 10000);
    });

    // Wrap diff.diffWords in a promise
    const diffPromise = new Promise<Change[]>((resolve) => {
      try {
        const diffArray = diff.diffWords(serverStr, clientStr);
        resolve(diffArray);
      } catch (error) {
        console.error(error);
        resolve([]);
      }
    });

    // Race the diffPromise against the timeoutPromise
    const diffArray = await Promise.race([diffPromise, timeoutPromise]);

    let highlightedServer = '';
    let highlightedClient = '';

    diffArray.forEach(({ added, removed, value }) => {
      if (added) {
        highlightedClient += wrapDiff(value, 'rgba(185, 246, 202, 1)');
      } else if (removed) {
        highlightedServer += wrapDiff(value, 'rgba(255, 138, 128, 1)');
      } else {
        highlightedServer += value;
        highlightedClient += value;
      }
    });

    return { highlightedServer, highlightedClient };
  }
  try {
    const { highlightedServer, highlightedClient } = await computeDiff(serverElement, clientElement);
    return { serverElement: highlightedServer, clientElement: highlightedClient };
  } catch (error) {
    console.error(error);
    return { serverElement: null, clientElement: null };
  }
}

function collapseUnchangedLinesInBoth(serverHtml: string, clientHtml: string) {
  const serverLines = serverHtml.split('\n');
  const clientLines = clientHtml.split('\n');

  const collapsedServer: string[] = [];
  const collapsedClient: string[] = [];

  let unchangedBufferServer: string[] = [];
  let unchangedBufferClient: string[] = [];

  let inServerHighlight = false;
  let inClientHighlight = false;

  for (let i = 0; i < Math.min(serverLines.length, clientLines.length); i++) {
    const serverLine = serverLines[i];
    const clientLine = clientLines[i];

    // Check if we are entering or exiting a highlighted section
    if (serverLine.includes('!highlight style=')) inServerHighlight = true;
    if (serverLine.includes('!/highlight')) inServerHighlight = false;

    if (clientLine.includes('!highlight style=')) inClientHighlight = true;
    if (clientLine.includes('!/highlight')) inClientHighlight = false;

    const isServerUnchanged = !serverLine.includes('!highlight style=');
    const isClientUnchanged = !clientLine.includes('!highlight style=');

    // Don't collapse lines if inside a highlight block
    if ((inServerHighlight || inClientHighlight) && isServerUnchanged && isClientUnchanged) {
      collapsedServer.push(serverLine);
      collapsedClient.push(clientLine);
      continue;
    }

    if (isServerUnchanged && isClientUnchanged) {
      unchangedBufferServer.push(serverLine);
      unchangedBufferClient.push(clientLine);
    } else {
      // Collapse only if the unchanged buffer is large enough and not inside a highlight
      if (unchangedBufferServer.length >= 3 && unchangedBufferClient.length >= 3) {
        collapsedServer.push(
          unchangedBufferServer[0],
          COLLAPSE_MESSAGE,
          unchangedBufferServer[unchangedBufferServer.length - 1]
        );
        collapsedClient.push(
          unchangedBufferClient[0],
          COLLAPSE_MESSAGE,
          unchangedBufferClient[unchangedBufferClient.length - 1]
        );
      } else {
        collapsedServer.push(...unchangedBufferServer);
        collapsedClient.push(...unchangedBufferClient);
      }

      unchangedBufferServer = [];
      unchangedBufferClient = [];

      collapsedServer.push(serverLine);
      collapsedClient.push(clientLine);
    }
  }

  // Handle any remaining unchanged lines at the end
  if (unchangedBufferServer.length >= 3 && unchangedBufferClient.length >= 3) {
    collapsedServer.push(
      unchangedBufferServer[0],
      COLLAPSE_MESSAGE,
      unchangedBufferServer[unchangedBufferServer.length - 1]
    );
    collapsedClient.push(
      unchangedBufferClient[0],
      COLLAPSE_MESSAGE,
      unchangedBufferClient[unchangedBufferClient.length - 1]
    );
  } else {
    collapsedServer.push(...unchangedBufferServer);
    collapsedClient.push(...unchangedBufferClient);
  }

  return {
    serverElementCollapsed: collapsedServer.join('\n'),
    clientElementCollapsed: collapsedClient.join('\n'),
  };
}

function alignHighlightedLines(
  serverHtml: string,
  clientHtml: string
): { serverElementAligned: string; clientElementAligned: string } {
  const serverLines = serverHtml.split('\n');
  const clientLines = clientHtml.split('\n');

  const alignedServer: string[] = [];
  const alignedClient: string[] = [];

  let i = 0;
  let j = 0;

  while (i < serverLines.length || j < clientLines.length) {
    const serverLine = serverLines[i] || '';
    const clientLine = clientLines[j] || '';

    // If lines match, add them both
    if (serverLine === clientLine) {
      alignedServer.push(serverLine);
      alignedClient.push(clientLine);
      i++;
      j++;
    }
    // If server has an extra line, add space on client side until they match
    else if (serverLines.slice(i).includes(clientLine)) {
      alignedServer.push(serverLine);
      alignedClient.push(''); // Add empty space to client
      i++;
    }
    // If client has an extra line, add space on server side until they match
    else if (clientLines.slice(j).includes(serverLine)) {
      alignedClient.push(clientLine);
      alignedServer.push(''); // Add empty space to server
      j++;
    }
    // If neither matches, assume both are different and add both
    else {
      alignedServer.push(serverLine);
      alignedClient.push(clientLine);
      i++;
      j++;
    }
  }

  return {
    serverElementAligned: alignedServer.join('\n'),
    clientElementAligned: alignedClient.join('\n'),
  };
}

function syncScroll(element1: HTMLElement, element2: HTMLElement) {
  let isSyncingLeft = false;
  let isSyncingRight = false;

  element1.addEventListener('scroll', () => {
    if (!isSyncingLeft) {
      isSyncingRight = true;
      element2.scrollLeft = element1.scrollLeft;
    }
    isSyncingLeft = false;
  });

  element2.addEventListener('scroll', () => {
    if (!isSyncingRight) {
      isSyncingLeft = true;
      element1.scrollLeft = element2.scrollLeft;
    }
    isSyncingRight = false;
  });
}

const createDiffPanel = async (initialServerHTML: string | null, initialClientHTML: string | null) => {
  content = document.createElement('div');
  content.style.cssText = CONTENT_STYLES;
  content.addEventListener('mousewheel', (e) => e.stopPropagation());
  content.setAttribute('data-hydration-content', '');

  const serverHtmlCleaned = cleanHtml(initialServerHTML || '');
  const clientHtmlCleaned = cleanHtml(initialClientHTML || '');
  const serverHtmlPrettified = prettifyHtml(serverHtmlCleaned);
  const clientHtmlPrettified = prettifyHtml(clientHtmlCleaned);
  const { serverElement, clientElement } =
    (await highlightDifferences(serverHtmlPrettified, clientHtmlPrettified)) || {};

  if (serverElement || clientElement) {
    const { serverElementAligned, clientElementAligned } = alignHighlightedLines(
      serverElement || '',
      clientElement || ''
    );
    const { serverElementCollapsed, clientElementCollapsed } = collapseUnchangedLinesInBoth(
      serverElementAligned,
      clientElementAligned
    );

    const serverPanel = document.createElement('div');
    serverPanel.style.cssText = PANEL_STYLES;
    serverPanel.innerHTML = `<strong>Server:</strong><br><pre>${escapeHTML(serverElementCollapsed)}</pre>`;

    const clientPanel = document.createElement('div');
    clientPanel.style.cssText = PANEL_STYLES;
    clientPanel.innerHTML = `<strong>Client:</strong><br><pre>${escapeHTML(clientElementCollapsed)}</pre>`;

    content.appendChild(serverPanel);
    content.appendChild(clientPanel);
    if (banner) {
      banner.appendChild(content);
      document.body.prepend(banner);
    }
    const serverPre = serverPanel.querySelector('pre');
    const clientPre = clientPanel.querySelector('pre');
    if (serverPre && clientPre) {
      syncScroll(serverPre, clientPre);
    }
  }
  if (loadingIndicator && loadingIndicator.parentNode) {
    loadingIndicator.parentNode.removeChild(loadingIndicator);
  }
};

// Additional variable to store initial server HTML
let initialServerHTML: string | null = null;
// use 10s timer to prevent from infinite loop
let timeoutStart: number | null = null;

// Function to capture initial server HTML when document is ready
export const captureServerHTML = () => {
  // If this is the first call, record the start time
  if (timeoutStart === null) {
    timeoutStart = Date.now();
  }

  // Check if 10 seconds have passed since the first call
  if (Date.now() - timeoutStart > 10000) {
    timeoutStart = null; // Reset the start time
    return; // Stop further execution
  }

  try {
    if (document?.documentElement?.outerHTML) {
      initialServerHTML = document.documentElement.outerHTML;
      timeoutStart = null; // Reset the start time
    } else {
      setTimeout(captureServerHTML, 50);
    }
  } catch (error) {
    console.error('Error capturing HTML:', error);
  }
};

const captureClientHTML = () => {
  if (!initialServerHTML) {
    return; // just skip since server not rendered
  }
  if (document?.documentElement?.outerHTML) {
    const initialClientHTML = document.documentElement.outerHTML;
    window.removeEventListener('load', captureClientHTMLAfterTimeout);
    if (initialServerHTML.includes('snowpack-loader')) return; // Skip if snowpack (CSR only)
    if (!banner) return; // Skip if no warning detected
    createDiffPanel(initialServerHTML, initialClientHTML);
  }
};

const captureClientHTMLAfterTimeout = () => {
  setTimeout(captureClientHTML, 0); // Allow React hydration to finish
};

export const setupHydrationWarningHandler = async () => {
  if (typeof window === 'undefined' || !document?.body) {
    return;
  }

  if (document?.readyState === 'complete' || document?.readyState === 'interactive') {
    // captureServerHTML();
  }

  window.addEventListener('load', captureClientHTMLAfterTimeout);
  let mismatchCount = 0;
  const originalConsoleError = console.error;

  console.error = (...args: ConsoleArgument[]) => {
    const formattedArgs = formatHydrationArgs(args);

    if (
      args.some((arg) => typeof arg === 'string' && HYDRATION_WARNING_PATTERNS.some((pattern) => arg.includes(pattern)))
    ) {
      console.warn('🚨 Hydration mismatch detected:', ...formattedArgs);
      console.warn('📍 Stack trace:', new Error().stack);

      mismatchCount++;

      // Create or reuse banner
      if (!banner && document?.body) {
        banner = document.createElement('div');
        banner.style.cssText = BANNER_STYLES;

        const closeButton = document.createElement('button');
        closeButton.style.cssText = CLOSE_BUTTON_STYLES;
        closeButton.textContent = '×';
        closeButton.onclick = () => {
          if (banner && document.body) document.body.removeChild(banner);
          banner = null; // Ensure banner can be recreated if needed
        };

        title = document.createElement('div');
        title.style.cssText = TITLE_STYLES;

        const subtitle = document.createElement('div');
        subtitle.style.cssText = 'text-align:center;padding:4px;font-size:14px;color:white;';
        subtitle.textContent = formattedArgs[0].split('.')[0];

        loadingIndicator = document.createElement('div');
        loadingIndicator.textContent = 'Loading diff...';
        loadingIndicator.style.cssText = 'text-align:center;padding:10px;font-size:14px;color:white;';

        banner.appendChild(closeButton);
        banner.appendChild(title);
        banner.appendChild(subtitle);
        banner.appendChild(loadingIndicator);
        document.body.prepend(banner);
      }

      // Update title with count
      if (title) {
        title.textContent = `⚠️ ${mismatchCount} hydration mismatch${mismatchCount === 1 ? '' : 'es'} detected`;
      }
    }
    originalConsoleError(...args);
  };
};
