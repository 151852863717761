////////////////////////////////////////////////////////////////////////////////
// This file was automatically generated by `yarn product-features:types` and
// should not be edited.
////////////////////////////////////////////////////////////////////////////////

export const IdTypes = {
  'Contract': 'Contract',
  'IntegrationTesting': 'IntegrationTesting',
  'IntegrationTesting2': 'IntegrationTesting2',
  'Organization': 'Organization',
  'Program': 'Program',
  'UnitTestId': 'UNIT_TEST_ID',
  'User': 'User',
} as const;

export type Namespaces = {
  'UnitTestNamespace': {
    'UnitTestFeature': boolean;
  };
  'degrees': {
    'enableAiCoachForDegrees': boolean;
    'enableDegreePathwayModuleCompletionNotification': boolean;
    'enableDegreesLIHPModule': boolean;
    'enableEnterpriseDegreeStackabilityCoreLEX': boolean;
    'enableInCourseExperienceCompletionState': boolean;
    'enableInCourseExperienceUpsellNotification': boolean;
    'enableLihpMyLearningTabUpsells': boolean;
    'enableLihpPathwayDegreesCollection': boolean;
    'enableStackability': boolean;
    'enableStackablyUnenrolledLIHPModule': boolean;
  };
  'diagnostics': {
    'enableSkillsDiagnostics': boolean;
  };
  'enterprise': {
    'allowDomainAllowlistedProgramsOnXDP': boolean;
    'allowOrgForCurriculumBuilder': boolean;
    'allowOrgForMoocFixedTerms': boolean;
    'allowOrgForSpecializationConfiguration': boolean;
    'allowShortFormContent': string;
    'allowShortFormContentBasedOnAudience': boolean;
    'alwaysReturnGradeInEnrollmentReports': boolean;
    'browseOnlyProgram': boolean;
    'businessApiAddSSOParamsToContentUrl': boolean;
    'businessApiExcludeSSOParamsFromContentUrl': boolean;
    'businessApiIncludeClipsInContentList': boolean;
    'businessApiIncludeSkillsetsAsContentTypeInContentList': boolean;
    'businessApiIncludeStandaloneClipsInContentList': boolean;
    'businessApiMaskClipsAsCoursesInContentList': boolean;
    'businessApiMaskSkillsetsAsCoursesInContentList': boolean;
    'contentCurationEnableC4B': boolean;
    'contentCurationEnableC4C': boolean;
    'contentCurationEnableC4G': boolean;
    'disableSimplifiedNavigation': boolean;
    'disableUpsellBanner': boolean;
    'enableAcademicDisciplines': boolean;
    'enableAiCoach': boolean;
    'enableAiCoachEarlyAccess': boolean;
    'enableAssignContent': boolean;
    'enableAssignmentCoachMVP': boolean;
    'enableC4CBFreemium': boolean;
    'enableCareerAcademyLaborMarketData': boolean;
    'enableCareerAcademyLimitedRestrictions': boolean;
    'enableCareerAcademyTestimonialsComponent': boolean;
    'enableCareerSupport': boolean;
    'enableClipsInCollections': boolean;
    'enableDataTechSku': boolean;
    'enableDayOneOnboardingFlow': boolean;
    'enableDigestibleSkills': boolean;
    'enableEnhancedLearnerProfilesForAdmin': boolean;
    'enableEnhancedUserFilters': boolean;
    'enableEnterpriseCustomBadges': boolean;
    'enableEnterpriseSpecificCollection': boolean;
    'enableEnterpriseTermsOfUseModal': boolean;
    'enableForceSsoLogin': boolean;
    'enableForceSsoLoginV2Beta': boolean;
    'enableFreemium': boolean;
    'enableGenAiHubPages': boolean;
    'enableGradebookPlagiarismReport': boolean;
    'enableGwG': boolean;
    'enableGwGForOrg': boolean;
    'enableGwGFreemium': boolean;
    'enableGwGPaid': boolean;
    'enableGwGProgram': boolean;
    'enableIntroClipsForSkillPages': boolean;
    'enableInvitedOnlyProgram': boolean;
    'enableLearnerProfileRecruiting': boolean;
    'enableLearnerProfileV1': boolean;
    'enableMarketData': boolean;
    'enableNewProgramCreation': boolean;
    'enableOptimizedLearnerFlow': boolean;
    'enableOrgHomepage': boolean;
    'enableOrgHomepageAutoJoinProgram': boolean;
    'enablePermissionBasedAdminAuth': boolean;
    'enableRecruitingAssessmentColumn': boolean;
    'enableRecruitingDemoOrg': boolean;
    'enableRenderDomainsMenu': boolean;
    'enableSelfServiceSso': boolean;
    'enableSkillsDashboard': boolean;
    'enableSkillsInSearchAndBrowse': boolean;
    'enableSkillsScoreColumns': boolean;
    'enableSkillsetRoleFilter': boolean;
    'enableUnstopIntegration': boolean;
    'enableUserFilterV2Backend': boolean;
    'enableV2SkillsTaxonomy': boolean;
    'enableV2SkillsTaxonomyForAdmin': boolean;
    'enableV2SkillsTaxonomyParentFeature': boolean;
    'enableXapiIntegration': boolean;
    'filledProgramCustomMessage': string;
    'inviteOnlyProgramDenyCustomMessage': string;
    'isAnonymousPeerReviewEnabled': boolean;
    'isCALimitedCIFeatureGated': boolean;
    'isCareerAcademyEnabled': boolean;
    'isCareerAcademyLimitedCatalog': boolean;
    'isCareerAcademySelfServeEnabled': boolean;
    'isDisallowPeerReviewUrlSharingEnabled': boolean;
    'isRolePageEstimatedCompletionTimeDisabled': boolean;
    'miniXdpEnrollAlternateCopy': boolean;
    'personalizedRecommendations': boolean;
    'programCollectionSectionDescription': boolean;
    'programContentBox': boolean;
    'programDenyCustomMessage': string;
    'programEnableNewLearnerJourney': boolean;
    'programEulaBanner': boolean;
    'programFreeStuffVerbiage': boolean;
    'programHideCourseraRecommendations': boolean;
    'programJoinAgeVerification': boolean;
    'programJoinCtaAlternateCopy': boolean;
    'programJoinTrackingCustomMessage': string;
    'programJoinTrackingVerbiage': boolean;
    'programRecommendationsAvailableCoursesCopy': boolean;
    'programSkillSetFilters': boolean;
    'programSponsoredByMessage': boolean;
    'promoteAllGuidedProjectsPage': boolean;
    'recruitingAnonymizationEnabled': boolean;
    'recruitingEnabled': boolean;
    'restrictHiringSolutions': boolean;
    'skillsEnableForEnterpriseAdmins': boolean;
    'stackabilityOnXdpForEnterpriseLearners': boolean;
  };
  'enterpriseEmails': {
    'allowAnyEmailsForEnterprise': boolean;
    'allowCreateProgramMembershipEmails': boolean;
    'allowDegreeBannersInEnterpriseLearnerEmails': boolean;
    'allowDeleteProgramMembershipEmails': boolean;
    'allowEnterpriseAdminInvitationEmails': boolean;
    'allowEnterpriseAdminRoleEmails': boolean;
    'allowEnterpriseLearnerReegnagementEmails': boolean;
    'allowEnterpriseLearnerReengagementEmailsV2': boolean;
    'allowEnterpriseLearnerWeeklyProgressEmails': boolean;
    'allowEnterpriseNewLearnerOnboardingEmails': boolean;
    'allowEnterpriseUserEmailUpdateEmails': boolean;
    'allowPendingProgramInvitationEmails': boolean;
    'allowProgramEnrollmentEmails': boolean;
    'allowProgramInvitationEmails': boolean;
    'allowProgramUnenrollmentEmails': boolean;
    'allowSkillSetAnnouncementEmails': boolean;
    'createProgramMembershipProcessorSwitchToJavaTimestamp': string;
    'hideNonCustomSectionEmailContent': boolean;
    'invitationProcessorSwitchToJavaTimestamp': string;
    'programUnenrollmentSwitchToJavaTimestamp': string;
    'rosterMembershipDeletionSwitchToJavaTimestamp': string;
    'templateIdDeleteProgramMembership': string;
    'templateIdFirstEnrollmentReminder': string;
    'templateIdFirstPendingProgramInvitation': string;
    'templateIdFirstUnenrollmentWarning': string;
    'templateIdProgramInvitation': string;
    'templateIdProgramInvitationArabic': string;
    'templateIdProgramInvitationUpswell': string;
    'templateIdProgramWelcome': string;
    'templateIdProgramWelcomeUpswell': string;
    'templateIdReengagement': string;
    'templateIdReengagementV2': string;
    'templateIdSecondEnrollmentReminder': string;
    'templateIdSecondPendingProgramInvitation': string;
    'templateIdSecondUnenrollmentWarning': string;
    'templateIdSkillSetAnnouncementEmail': string;
    'templateIdSoftDeleteProgramMembership': string;
  };
  'globalEmails': {
    'allowCourseEmails': boolean;
    'allowPartnerEmails': boolean;
  };
  'integrationTesting': {
    'testFeature1WithoutAudiences': boolean;
    'testFeature2WithoutAudiences': boolean;
    'testFeatureParent': boolean;
    'testFeaturePrecedenceRules': boolean;
    'testFeatureUserIdAutoPopulation': boolean;
    'testFeatureWithAttributeServiceDependentAudience': boolean;
    'testFeatureWithNostosDependentAudience': boolean;
    'testFeatureWithNostosRelations': boolean;
    'testFeatureWithoutAudiences': boolean;
  };
};

export const Defaults: Namespaces = {
  'UnitTestNamespace': {
    'UnitTestFeature': false,
  },
  'degrees': {
    'enableAiCoachForDegrees': false,
    'enableDegreePathwayModuleCompletionNotification': false,
    'enableDegreesLIHPModule': true,
    'enableEnterpriseDegreeStackabilityCoreLEX': false,
    'enableInCourseExperienceCompletionState': false,
    'enableInCourseExperienceUpsellNotification': false,
    'enableLihpMyLearningTabUpsells': false,
    'enableLihpPathwayDegreesCollection': false,
    'enableStackability': false,
    'enableStackablyUnenrolledLIHPModule': false,
  },
  'diagnostics': {
    'enableSkillsDiagnostics': true,
  },
  'enterprise': {
    'allowDomainAllowlistedProgramsOnXDP': true,
    'allowOrgForCurriculumBuilder': true,
    'allowOrgForMoocFixedTerms': true,
    'allowOrgForSpecializationConfiguration': true,
    'allowShortFormContent': "",
    'allowShortFormContentBasedOnAudience': true,
    'alwaysReturnGradeInEnrollmentReports': false,
    'browseOnlyProgram': false,
    'businessApiAddSSOParamsToContentUrl': false,
    'businessApiExcludeSSOParamsFromContentUrl': false,
    'businessApiIncludeClipsInContentList': false,
    'businessApiIncludeSkillsetsAsContentTypeInContentList': false,
    'businessApiIncludeStandaloneClipsInContentList': false,
    'businessApiMaskClipsAsCoursesInContentList': false,
    'businessApiMaskSkillsetsAsCoursesInContentList': false,
    'contentCurationEnableC4B': false,
    'contentCurationEnableC4C': false,
    'contentCurationEnableC4G': false,
    'disableSimplifiedNavigation': false,
    'disableUpsellBanner': false,
    'enableAcademicDisciplines': false,
    'enableAiCoach': false,
    'enableAiCoachEarlyAccess': false,
    'enableAssignContent': true,
    'enableAssignmentCoachMVP': true,
    'enableC4CBFreemium': false,
    'enableCareerAcademyLaborMarketData': true,
    'enableCareerAcademyLimitedRestrictions': false,
    'enableCareerAcademyTestimonialsComponent': true,
    'enableCareerSupport': false,
    'enableClipsInCollections': false,
    'enableDataTechSku': false,
    'enableDayOneOnboardingFlow': true,
    'enableDigestibleSkills': true,
    'enableEnhancedLearnerProfilesForAdmin': true,
    'enableEnhancedUserFilters': true,
    'enableEnterpriseCustomBadges': false,
    'enableEnterpriseSpecificCollection': false,
    'enableEnterpriseTermsOfUseModal': false,
    'enableForceSsoLogin': false,
    'enableForceSsoLoginV2Beta': false,
    'enableFreemium': false,
    'enableGenAiHubPages': false,
    'enableGradebookPlagiarismReport': false,
    'enableGwG': false,
    'enableGwGForOrg': false,
    'enableGwGFreemium': false,
    'enableGwGPaid': false,
    'enableGwGProgram': false,
    'enableIntroClipsForSkillPages': false,
    'enableInvitedOnlyProgram': false,
    'enableLearnerProfileRecruiting': false,
    'enableLearnerProfileV1': true,
    'enableMarketData': false,
    'enableNewProgramCreation': false,
    'enableOptimizedLearnerFlow': false,
    'enableOrgHomepage': false,
    'enableOrgHomepageAutoJoinProgram': false,
    'enablePermissionBasedAdminAuth': false,
    'enableRecruitingAssessmentColumn': false,
    'enableRecruitingDemoOrg': true,
    'enableRenderDomainsMenu': true,
    'enableSelfServiceSso': false,
    'enableSkillsDashboard': false,
    'enableSkillsInSearchAndBrowse': true,
    'enableSkillsScoreColumns': false,
    'enableSkillsetRoleFilter': true,
    'enableUnstopIntegration': true,
    'enableUserFilterV2Backend': true,
    'enableV2SkillsTaxonomy': false,
    'enableV2SkillsTaxonomyForAdmin': false,
    'enableV2SkillsTaxonomyParentFeature': false,
    'enableXapiIntegration': false,
    'filledProgramCustomMessage': "",
    'inviteOnlyProgramDenyCustomMessage': "",
    'isAnonymousPeerReviewEnabled': false,
    'isCALimitedCIFeatureGated': true,
    'isCareerAcademyEnabled': false,
    'isCareerAcademyLimitedCatalog': false,
    'isCareerAcademySelfServeEnabled': false,
    'isDisallowPeerReviewUrlSharingEnabled': false,
    'isRolePageEstimatedCompletionTimeDisabled': false,
    'miniXdpEnrollAlternateCopy': false,
    'personalizedRecommendations': true,
    'programCollectionSectionDescription': true,
    'programContentBox': true,
    'programDenyCustomMessage': "",
    'programEnableNewLearnerJourney': true,
    'programEulaBanner': false,
    'programFreeStuffVerbiage': true,
    'programHideCourseraRecommendations': false,
    'programJoinAgeVerification': false,
    'programJoinCtaAlternateCopy': false,
    'programJoinTrackingCustomMessage': "",
    'programJoinTrackingVerbiage': true,
    'programRecommendationsAvailableCoursesCopy': false,
    'programSkillSetFilters': false,
    'programSponsoredByMessage': true,
    'promoteAllGuidedProjectsPage': false,
    'recruitingAnonymizationEnabled': true,
    'recruitingEnabled': false,
    'restrictHiringSolutions': false,
    'skillsEnableForEnterpriseAdmins': false,
    'stackabilityOnXdpForEnterpriseLearners': true,
  },
  'enterpriseEmails': {
    'allowAnyEmailsForEnterprise': true,
    'allowCreateProgramMembershipEmails': true,
    'allowDegreeBannersInEnterpriseLearnerEmails': false,
    'allowDeleteProgramMembershipEmails': true,
    'allowEnterpriseAdminInvitationEmails': true,
    'allowEnterpriseAdminRoleEmails': true,
    'allowEnterpriseLearnerReegnagementEmails': true,
    'allowEnterpriseLearnerReengagementEmailsV2': true,
    'allowEnterpriseLearnerWeeklyProgressEmails': true,
    'allowEnterpriseNewLearnerOnboardingEmails': true,
    'allowEnterpriseUserEmailUpdateEmails': true,
    'allowPendingProgramInvitationEmails': true,
    'allowProgramEnrollmentEmails': true,
    'allowProgramInvitationEmails': true,
    'allowProgramUnenrollmentEmails': true,
    'allowSkillSetAnnouncementEmails': true,
    'createProgramMembershipProcessorSwitchToJavaTimestamp': "1630694708000",
    'hideNonCustomSectionEmailContent': false,
    'invitationProcessorSwitchToJavaTimestamp': "1630431000000",
    'programUnenrollmentSwitchToJavaTimestamp': "1633629600000",
    'rosterMembershipDeletionSwitchToJavaTimestamp': "1633010400000",
    'templateIdDeleteProgramMembership': "program.membership.deletion",
    'templateIdFirstEnrollmentReminder': "program.firstEnrollmentTrigger.sl_test.v4",
    'templateIdFirstPendingProgramInvitation': "program.firstPendingInvitationTrigger.sl_test.v7",
    'templateIdFirstUnenrollmentWarning': "program.firstUnenrollmentTrigger",
    'templateIdProgramInvitation': "program.invitation.v5",
    'templateIdProgramInvitationArabic': "program.invitation.arabic",
    'templateIdProgramInvitationUpswell': "program.invitation.upswell.v2",
    'templateIdProgramWelcome': "program.welcome.v3",
    'templateIdProgramWelcomeUpswell': "program.welcome.upswell.v2",
    'templateIdReengagement': "enterprise.learner.reengagement.v1",
    'templateIdReengagementV2': "enterprise.learner.reengagement.v2",
    'templateIdSecondEnrollmentReminder': "program.secondEnrollmentTrigger",
    'templateIdSecondPendingProgramInvitation': "program.secondPendingInvitationTrigger.sl_test.v6",
    'templateIdSecondUnenrollmentWarning': "program.secondUnenrollmentTrigger",
    'templateIdSkillSetAnnouncementEmail': "program.skillsetAnnouncement.v1",
    'templateIdSoftDeleteProgramMembership': "program.membership.softdeletion",
  },
  'globalEmails': {
    'allowCourseEmails': true,
    'allowPartnerEmails': true,
  },
  'integrationTesting': {
    'testFeature1WithoutAudiences': false,
    'testFeature2WithoutAudiences': false,
    'testFeatureParent': false,
    'testFeaturePrecedenceRules': false,
    'testFeatureUserIdAutoPopulation': false,
    'testFeatureWithAttributeServiceDependentAudience': false,
    'testFeatureWithNostosDependentAudience': false,
    'testFeatureWithNostosRelations': false,
    'testFeatureWithoutAudiences': false,
  },
};

