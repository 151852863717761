/* @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { Avatar } from '@coursera/cds-core';

import { firstNameInitialFromName } from 'bundles/ai-coach-platform/utils/nameParser';

import _t from 'i18n!nls/ai-coach-platform';

type Props = {
  imageUrl?: string;
  name: string;
};

const LearnerAvatar = ({ imageUrl, name }: Props) => {
  return (
    <Avatar
      aria-label={_t('Learner avatar')}
      aria-hidden
      variant="user"
      showBorder={false}
      initial={firstNameInitialFromName(name)}
      imgProps={{
        src: imageUrl,
      }}
      css={css`
        flex-shrink: 0;
        width: 32px !important;
        height: 32px !important;

        .cds-Avatar-initial {
          background-color: #00255d;
          width: 32px;
          height: 32px;
        }
      `}
    />
  );
};

export default LearnerAvatar;
