/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';

import { IconButton, type IconButtonProps, NotificationBadge, type NotificationBadgeProps } from '@coursera/cds-core';

import CoachIcon from 'bundles/ai-coach-platform/components/branding/CoachIcon';

import _t from 'i18n!nls/ai-coach-platform';

enum IconState {
  DEFAULT = 'default',
  WINK = 'wink',
  DISABLED = 'disabled',
}

type Props = {
  /**
   * Callback when the launcher CTA is clicked
   */
  onClick?: (selected: boolean) => void;

  /**
   * whether CTA is selected by default
   *
   * @default false
   */
  defaultSelected?: boolean;

  /**
   * whether Coach is disabled on the page
   *
   * @default false
   */
  disabled?: boolean;

  /**
   * size of the Coach icon
   *
   * @default medium
   */
  size?: IconButtonProps['size'];

  tooltip?: string;

  /**
   * Count of notifications to show badge for. Hidden when count is <= 0 or undefined.
   * The max is "9" above which it will render "9+".
   * Implementing team is responsible to update/unset the count based on business logic.
   *
   * @default 0
   */
  notificationCount?: NotificationBadgeProps['value'];
};

const styles = {
  iconButton: (isSelected: boolean) => css`
    ${isSelected &&
    `
      .cds-button-label {
        color: var(--cds-color-blue-600);
      }

      background-color: var(--cds-color-emphasis-primary-background-xweak);
      border-radius: var(--cds-border-radius-100);
    `}
  `,
  notificationBadge: css`
    /* Coach theme overrides */
    .cds-notificationBadge-default {
      background: linear-gradient(
        89deg,
        var(--cds-color-emphasis-primary-content-default) -7.38%,
        var(--cds-color-blue-600) 124.79%
      );
    }
  `,
};

export const CoachChatLauncher = (props: Props): React.ReactElement => {
  const { onClick, disabled = false, size = 'medium', tooltip, defaultSelected = false, notificationCount = 0 } = props;

  const [coachIconState, setCoachIconState] = useState<IconState>(IconState.DEFAULT);
  const [selected, setSelected] = useState(defaultSelected);

  const containerRef = React.useRef<HTMLDivElement>(null);

  const currentIconState = disabled ? IconState.DISABLED : coachIconState;

  useEffect(() => {
    setSelected(defaultSelected);
  }, [defaultSelected]);

  useEffect(() => {
    const handleChatClose = () => {
      // [ACCESS-4084] when chat is closed with Chat close button,
      // set focus back to triggering launcher button
      containerRef.current?.focus();
    };

    if (!disabled) {
      // listen to custom event when Chat close button is clicked
      document.body.addEventListener('coachChatClosed', handleChatClose);
    }

    return () => {
      document.body.removeEventListener('coachChatClosed', handleChatClose);
    };
  }, [disabled]);

  const handleMouseEnter = useCallback<() => void>(() => {
    setCoachIconState(IconState.WINK);
  }, []);
  const handleFocus = useCallback<() => void>(() => {
    setCoachIconState(IconState.WINK);
  }, []);

  const handleMouseLeave = useCallback<() => void>(() => {
    setCoachIconState(IconState.DEFAULT);
  }, []);
  const handleBlur = useCallback<() => void>(() => {
    setCoachIconState(IconState.DEFAULT);
  }, []);

  const handleClick = () => {
    setSelected(!selected);
    onClick?.(!selected);
  };

  return (
    <div ref={containerRef} tabIndex={-1} data-testid="coach-chat-launcher-container">
      <IconButton
        onMouseEnter={disabled ? undefined : handleMouseEnter}
        onMouseLeave={disabled ? undefined : handleMouseLeave}
        onFocus={disabled ? undefined : handleFocus}
        onBlur={disabled ? undefined : handleBlur}
        onClick={disabled ? undefined : handleClick}
        variant="ghost"
        aria-disabled={disabled} // `aria-disabled` allows users to view tooltip while in disabled state
        icon={
          <NotificationBadge value={notificationCount} align="top" css={styles.notificationBadge} max={9}>
            <CoachIcon state={currentIconState} size={size} />
          </NotificationBadge>
        }
        tooltip={
          disabled
            ? _t('Coursera #{coachLabel} is not available on this page.', { coachLabel: 'Coach' })
            : tooltip ?? _t('Chat with Coursera #{coachLabel}', { coachLabel: 'Coach' })
        }
        aria-label={
          tooltip ?? _t('Chat with #{coachLabel}, your AI-powered learning assistant', { coachLabel: 'Coach' })
        }
        aria-expanded={selected}
        data-testid="coach-chat-launcher-button"
        css={[styles.iconButton(selected)]}
      />
    </div>
  );
};

export default CoachChatLauncher;
