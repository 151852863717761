import { capitalize } from 'lodash';

import type {
  ClickAutocompleteSuggestions,
  Partner,
  Product,
  SuggestionSection,
  SuggestionState,
  SuggestionType,
} from '@coursera/event-pulse-types';

import type {
  DiscoveryCollection,
  DiscoveryCollectionsNonClipDegreeNorMasterTrackEntity,
} from 'bundles/browse/components/types/DiscoveryCollections';
import { capitalizeAndTranslateEachWordInEntityType } from 'bundles/product-card/components/utils/productCardV2Utils';
import {
  LEARNING_PRODUCTS_ENUM_MAP,
  ON_SITE_SEARCH_PATH,
  PRODUCT_DURATIONS_STRING,
  RECENTLY_VIEWED_LOCAL_STORAGE_NAME,
  RECENT_SEARCHES_LOCAL_STORAGE_NAME,
  getTranslatedDifficulty,
  getTranslatedDurations,
} from 'bundles/search-common/constants';
import {
  AS_YOU_TYPE_SEARCH_GROUP_ID,
  AUTOCOMPLETE_MODES,
  AUTOCOMPLETE_PHOTO_SIZE,
  AUTOCOMPLETE_PREFIX_DELIMITER,
  AUTOCOMPLETE_PRODUCT_TYPE_TO_ICON,
  AUTOCOMPLETE_PRODUCT_TYPE_TO_TRACKING_NAME,
  AUTOCOMPLETE_SECTION_PREFIXES,
  type AutocompleteMode,
  MAX_SUGGESTION_COUNT,
  SearchesIcon,
  TRENDING_SEARCHES_GROUP_ID,
  ZERO_STATE_ITEMS_PER_SECTION,
  autocompletePrefixes,
  getAutocompleteProductTypePrettyName,
  getAutocompleteProductTypeSectionId,
  getTop10PartnerOptions,
} from 'bundles/search-common/constants/autocomplete';
import type {
  SearchHit,
  SearchProductHit,
  SearchResult,
  SearchResults,
  SearchSuggestionHit,
} from 'bundles/search-common/providers/searchTypes';
import type { AutoCompleteHits, Hits, SuggestionHits } from 'bundles/search-common/types/autocomplete';
import {
  getItemsListFromLocalStorageByKeyAndTrimOutdated,
  getProductType,
} from 'bundles/search-common/utils/SearchUtils';
import { isSearchProductHit } from 'bundles/search-common/utils/utils';

import _t from 'i18n!nls/search';

// helper type
type AutocompleteSection = Hits & { sectionTitle?: string | boolean; sectionId?: string; hits?: AutoCompleteHits };
type ReturnValue = AutocompleteSection[];
type SearchProductHitWithTracking = SearchProductHit & { indexName?: string | null };
type SearchSuggestionHitWithTracking = SearchSuggestionHit & { indexName?: string | null };
type HitWithTracking = (SearchProductHitWithTracking | SearchSuggestionHitWithTracking)[];

function filterUndefined<T>(item: T | undefined | null): item is T {
  return !!(item as T);
}

const formatTerms = (terms: (string | undefined)[]) => {
  if (!terms) return [];
  const sectionItems = terms.map((termName: string | undefined, termPosition: number) => ({
    name: termName,
    image: SearchesIcon,
    indexPosition: 0,
    objectID: `zero-state-data~${termName}`,
    hitPosition: termPosition,
  }));
  return sectionItems;
};

// Type guard to check if an entity has a difficulty level
function getDifficultyLevel(entity: DiscoveryCollectionsNonClipDegreeNorMasterTrackEntity | null | undefined): string {
  if (entity && typeof entity.difficultyLevel === 'string') {
    return capitalize(entity.difficultyLevel);
  } else {
    return '';
  }
}

export const getRecentlyViewedAutocompleteItems = (
  recentlyViewedCollection?: DiscoveryCollection | null
): AutocompleteItems => {
  const recentlyViewedProducts = getItemsListFromLocalStorageByKeyAndTrimOutdated(RECENTLY_VIEWED_LOCAL_STORAGE_NAME);
  const products = [];

  const localRecentlyViewedProducts = recentlyViewedProducts.map((recentItem) => {
    const productType = recentItem.productType ?? '';
    const difficulty = recentItem.difficulty ?? '';

    const supportText = [recentItem.partnerName, productType, difficulty].filter(Boolean).join(' • ');

    return {
      id: `${AUTOCOMPLETE_SECTION_PREFIXES.RECENTLY_VIEWED}${recentItem.name}` || '',
      name: recentItem.name || '',
      imageUrl: recentItem.partnerLogo || '',
      supportText: supportText || '',
      path: recentItem.path || '',
      eventProductId: recentItem.id,
    };
  });
  products.push(...localRecentlyViewedProducts);
  // If there are fewer than 3 recently viewed products, append products from recentlyViewedCollection
  // collection lags so we need to use local storage for most recent viewed product
  if (products.length < 3 && recentlyViewedCollection?.entities) {
    const existingProductNames = new Set(products.map((product) => product.name));

    const additionalProducts = recentlyViewedCollection.entities
      .filter((entity) => entity && !existingProductNames.has(entity.name))
      .slice(0, 3 - products.length)
      .map((entity) => {
        const difficultyLevel = getDifficultyLevel(entity as DiscoveryCollectionsNonClipDegreeNorMasterTrackEntity);
        const marketingProductType = getProductType(
          (entity as DiscoveryCollectionsNonClipDegreeNorMasterTrackEntity)?.productCard?.marketingProductType as string
        );

        const supportText = [
          entity?.partners?.map((partner) => partner?.name).join(', '),
          marketingProductType,
          difficultyLevel,
        ]
          .filter(Boolean)
          .join(' • ');

        return {
          id: `${AUTOCOMPLETE_SECTION_PREFIXES.RECENTLY_VIEWED}${entity?.name}` || '',
          name: entity?.name || '',
          imageUrl: entity?.partners ? entity?.partners[0]?.logo || '' : '',
          supportText: supportText || '',
          path: entity?.url || '',
          eventProductId: entity?.id,
        };
      });

    products.push(...additionalProducts);
  }
  return products.slice(0, 3);
};
export const getRecentlySearchedAutocompleteItems = (): AutocompleteItems => {
  const recentlySearchedTerms = getItemsListFromLocalStorageByKeyAndTrimOutdated(RECENT_SEARCHES_LOCAL_STORAGE_NAME);
  return recentlySearchedTerms
    .map((term) => ({
      id: `${AUTOCOMPLETE_SECTION_PREFIXES.RECENT_SEARCHES}${term}`,
      name: term,
    }))
    .slice(0, 5);
};

const getRecentlyViewedSectionItems = () => {
  const recentlyViewedProducts = getItemsListFromLocalStorageByKeyAndTrimOutdated(RECENTLY_VIEWED_LOCAL_STORAGE_NAME);
  if (recentlyViewedProducts.length === 0) return [];
  const sectionItems = recentlyViewedProducts.map((recentItem, itemPosition) => ({
    name: recentItem.name,
    partners: [recentItem.partnerName],
    objectUrl: recentItem.path,
    image: recentItem.image,
    indexPosition: 1,
    objectID: `zero-state-data~${recentItem.name}`,
    hitPosition: itemPosition,
  }));
  return sectionItems.slice(0, ZERO_STATE_ITEMS_PER_SECTION);
};

export const getFirstSectionData = (
  collectionRecommendations?: DiscoveryCollection | null
): AutocompleteSection | undefined => {
  const moduleID = collectionRecommendations?.id || '';
  const getSectionTitle = (id: string) => {
    let translatedSectionTitle = '';
    if (id.includes('recent-views')) translatedSectionTitle = _t('Based on your searches');
    if (id.includes('cold-start')) translatedSectionTitle = _t('Recommended for you');
    if (id.includes('trendingByEnrollmentsNumericTag')) translatedSectionTitle = _t('Most Popular Specializations');
    return translatedSectionTitle;
  };
  const getSectionId = (id: string) => {
    let sectionId = '';
    if (id.includes('recent-views')) sectionId = 'recently_viewed';
    if (id.includes('cold-start')) sectionId = 'suggested_searches';
    if (id.includes('trendingByEnrollmentsNumericTag')) sectionId = 'most_popular_specializations';
    return sectionId;
  };
  const inputRecommendationsData = moduleID.includes('trendingByEnrollmentsNumericTag')
    ? collectionRecommendations?.entities?.filter(
        (e) =>
          e?.__typename === 'DiscoveryCollections_specialization' ||
          e?.__typename === 'DiscoveryCollections_professionalCertificate'
      )
    : collectionRecommendations?.entities;
  const recentlyViewedData = getRecentlyViewedSectionItems();
  const { sectionTitle, sectionItems, sectionId } =
    recentlyViewedData.length > 0
      ? {
          sectionTitle: _t('Recently viewed'),
          sectionId: 'recently_viewed',
          sectionItems: recentlyViewedData as Partial<Hits>[],
        }
      : {
          sectionTitle: getSectionTitle(moduleID),
          sectionId: getSectionId(moduleID),
          sectionItems: inputRecommendationsData
            ?.filter((rec) => !!rec)
            .map((rec) => ({
              ...rec,
              image: { imageUrl: rec?.imageUrl, size: AUTOCOMPLETE_PHOTO_SIZE },
              partners: rec?.partners?.map((p) => p?.name),
              objectUrl: rec?.url,
            })) as Partial<Hits>[],
        };
  if (sectionItems) {
    return {
      sectionTitle,
      hits: sectionItems
        .slice(0, ZERO_STATE_ITEMS_PER_SECTION)
        .map(
          (suggestion) =>
            ({ ...suggestion, sectionTitle, sectionId, trackingName: 'zero_state_product' } as SuggestionHits)
        ),
    } as AutocompleteSection;
  }
  return undefined;
};

const getSecondSectionData = (suggestionHits?: SearchSuggestionHit[]): AutocompleteSection | undefined => {
  const recentSearches = getItemsListFromLocalStorageByKeyAndTrimOutdated(RECENT_SEARCHES_LOCAL_STORAGE_NAME);
  const { sectionItems, sectionTitle, sectionId } =
    recentSearches.length > 0
      ? {
          sectionItems: formatTerms(recentSearches),
          sectionTitle: _t('Recent Searches'),
          sectionId: 'recent_searches',
        }
      : {
          sectionItems:
            suggestionHits &&
            formatTerms(suggestionHits.map((term: SearchSuggestionHit) => term.name).filter(filterUndefined)),
          sectionTitle: _t('Popular right now'),
          sectionId: 'popular_right_now',
        };

  if (sectionItems) {
    return {
      sectionTitle,
      hits: sectionItems.slice(0, ZERO_STATE_ITEMS_PER_SECTION).map(
        (suggestion) =>
          ({
            ...suggestion,
            sectionTitle,
            sectionId,
            trackingName: 'zero_state_suggestion',
          } as SuggestionHits)
      ),
    } as AutocompleteSection;
  }
  return undefined;
};

const getSearchZeroStateSections = (
  collectionRecommendations?: DiscoveryCollection | null,
  suggestionHits?: SearchSuggestionHit[]
): ReturnValue => {
  return [getFirstSectionData(collectionRecommendations), getSecondSectionData(suggestionHits)].filter(filterUndefined);
};

const getSectionTitle = (hits: HitWithTracking) => {
  return hits[0]?.__typename !== 'Search_ProductHit'
    ? getAutocompleteProductTypePrettyName()[hits[0]?.__typename || 'Search_SuggestionHit']
    : undefined;
};

const getSectionId = (hits: HitWithTracking) => {
  return hits[0]?.__typename !== 'Search_ProductHit'
    ? getAutocompleteProductTypeSectionId()[hits[0]?.__typename || 'Search_SuggestionHit']
    : undefined;
};
const mapSearchSuggestionHits = (hits: HitWithTracking, indexPosition = 0): AutoCompleteHits => {
  const sectionTitle = getSectionTitle(hits);
  const sectionId = getSectionId(hits);
  return hits?.map(
    (hit, hitPosition: number): SuggestionHits => ({
      ...hit,
      name: hit.name || undefined,
      partners: (hit?.__typename === 'Search_ProductHit' && hit?.partners) || undefined,
      hitPosition,
      indexName: hit.indexName || '',
      indexPosition,
      tagline: (hit?.__typename === 'Search_ProductHit' && hit.tagline) || '',
      objectUrl: (hit?.__typename === 'Search_ProductHit' && hit?.url) || '',
      trackingName: AUTOCOMPLETE_PRODUCT_TYPE_TO_TRACKING_NAME[hit?.__typename || 'Search_ProductHit'],
      // @ts-expect-error URL: string
      image:
        hit?.__typename === 'Search_ProductHit'
          ? { imageUrl: hit?.imageUrl, size: AUTOCOMPLETE_PHOTO_SIZE }
          : AUTOCOMPLETE_PRODUCT_TYPE_TO_ICON[hit?.__typename || 'Search_SuggestionHit'],
      sectionTitle,
      sectionId,
    })
  );
};

const mapSearchSuggestionSection = (hits: HitWithTracking, indexPosition = 0): AutocompleteSection | undefined => {
  const rawHits = hits[0];
  const sectionTitle = getSectionTitle(hits);
  if (!rawHits) return undefined;
  const suggestionHits = mapSearchSuggestionHits(hits, indexPosition);

  return {
    // the item below is just to comply with the weird typing of react-autosuggest
    ...suggestionHits[0],
    index: '',
    name: '',
    objectUrl: '',
    trackingName: '',
    indexName: '',
    image: { size: 16, ...suggestionHits[0].image },
    partners: suggestionHits[0].partners || [],
    tagline: suggestionHits[0].tagline || '',
    objectID: suggestionHits[0].objectID || '',
    hitPosition: suggestionHits[0].hitPosition || 0,
    indexPosition: suggestionHits[0].indexPosition || 0,
    // until this line
    sectionTitle,
    // If it's index used for direct match, we just show one result on this section
    hits: suggestionHits,
  };
};

const getSearchSuggestions = (
  query: string,
  hits: SearchProductHitWithTracking[] = [],
  suggestionHits: SearchSuggestionHitWithTracking[] = []
): ReturnValue => {
  // For each index, take the first n hits according to the config
  // Product spec is to show direct match if user typed more than 3 characters
  const shouldShowDirectMatch = query.length >= 3;
  if (shouldShowDirectMatch) {
    const mainSection = mapSearchSuggestionSection(hits, 0);
    const suggestionSection = mapSearchSuggestionSection(suggestionHits, 1);
    return [mainSection, suggestionSection].filter(filterUndefined);
  } else {
    const suggestionSection = mapSearchSuggestionSection(suggestionHits, 0);
    return [suggestionSection].filter(filterUndefined);
  }
};

export function getAutoCompleteSections(
  query: string,
  mainSearchResult?: SearchResult,
  suggestionsData?: SearchResult,
  collectionRecommendations?: DiscoveryCollection | null
): ReturnValue {
  const { elements, source } = mainSearchResult || {};
  const indexName = source?.indexName;
  const hits = (elements as SearchProductHit[])?.map((hit: SearchProductHit) => ({ ...hit, indexName }));

  const { elements: suggestionElements, source: suggestionSource } = suggestionsData || {};
  const suggestionIndexName = suggestionSource?.indexName;
  const suggestionHits = (suggestionElements as SearchSuggestionHit[])?.map((hit: SearchSuggestionHit) => ({
    ...hit,
    indexName: suggestionIndexName,
  }));

  if (elements?.length === 0 || query.length < 1) {
    return getSearchZeroStateSections(collectionRecommendations, suggestionHits);
  } else {
    return getSearchSuggestions(query, hits, suggestionHits);
  }
}

export type AutocompleteItems = {
  id: string;
  name: string;
  imageUrl?: string;
  supportText?: string;
  path?: string;
  textValue?: string;
  eventProductId?: string;
}[];

export type AutocompleteItemGroup = {
  id: string;
  title: string;
  children: AutocompleteItems;
};

export const mapSearchDataToAutocompleteItems = (elements: SearchHit[], groupId: string): AutocompleteItems => {
  const prefix =
    groupId === AS_YOU_TYPE_SEARCH_GROUP_ID
      ? AUTOCOMPLETE_SECTION_PREFIXES.AS_YOU_TYPE_SEARCH
      : AUTOCOMPLETE_SECTION_PREFIXES.TRENDING_SEARCHES;
  return elements
    .map(
      (item) =>
        item.name &&
        item.id && {
          id: `${prefix}${item.id}`,
          name: item.name,
        }
    )
    .filter(Boolean);
};

export function getSearchUpdatedUrl(searchText: string = '', query: Record<string, string>) {
  const params = new URLSearchParams(query);
  params.set('query', searchText);
  return ON_SITE_SEARCH_PATH + '?' + params.toString().replace(/\+/g, '%20');
}

//* infer mode from query and recent items */
export function inferAutocompleteMode(
  query: string,
  recentlySearchedItems: AutocompleteItems,
  recentlyViewedItems: AutocompleteItems
): AutocompleteMode {
  let autocompleteMode: AutocompleteMode = AUTOCOMPLETE_MODES.AUTOCOMPLETE_ZERO_STATE;
  // if the user has searched before and the current query empty, show the "repeat search" state
  if ((recentlySearchedItems.length || recentlyViewedItems.length) && !query.length) {
    autocompleteMode = AUTOCOMPLETE_MODES.AUTOCOMPLETE_REPEAT_SEARCH;
    // otherwise,if there's a query, we should show the "as you type" state
  } else if (query.length) {
    autocompleteMode = AUTOCOMPLETE_MODES.AUTOCOMPLETE_AS_YOU_TYPE;
  }
  return autocompleteMode;
}

export const isExactPartnerNameMatch = (query: string) =>
  getTop10PartnerOptions().some((partner) => partner.name === query);

export function createRepeatSearchAutocompleteItems(
  recentlySearchedItems: AutocompleteItems,
  recentlyViewedData: AutocompleteItems,
  trendingResults: SearchResults | undefined
): AutocompleteItemGroup[] {
  const repeatSearchResults = [];
  if (recentlySearchedItems.length > 0) {
    repeatSearchResults.push({
      id: 'recentSearches',
      title: _t('Recent searches'),
      children: recentlySearchedItems,
    });
  }

  if (recentlyViewedData.length > 0) {
    repeatSearchResults.push({ id: 'recentViewed', title: _t('Recently viewed'), children: recentlyViewedData });
  }

  repeatSearchResults.push({
    id: TRENDING_SEARCHES_GROUP_ID,
    title: _t('Trending topics'),
    children: mapSearchDataToAutocompleteItems(
      trendingResults?.[0].elements.slice(0, 3) || [],
      TRENDING_SEARCHES_GROUP_ID
    ),
  });

  return repeatSearchResults;
}

export function isLoosePartnerNameMatch(partnerName: string, searchText: string): boolean {
  const lowerCaseSearchText = searchText.toLowerCase().trim();
  const lowerCasePartnerName = partnerName.toLowerCase();
  if (!lowerCasePartnerName || !lowerCaseSearchText) return false;
  const isExactMatchFromStart = lowerCasePartnerName.startsWith(lowerCaseSearchText);
  const hasWordMatch = lowerCasePartnerName.split(' ').some((word) => word.startsWith(lowerCaseSearchText));
  return isExactMatchFromStart || hasWordMatch;
}

export function createAsYouTypeAutocompleteItems(
  mainSearchDataElements: SearchHit[] | undefined,
  searchText: string,
  directMatchResult: SearchHit[] | undefined
): AutocompleteItemGroup[] {
  const autocompleteOptions = mapSearchDataToAutocompleteItems(
    mainSearchDataElements || [],
    AS_YOU_TYPE_SEARCH_GROUP_ID
  );

  const partnerHit = getTop10PartnerOptions().find(({ name }) => isLoosePartnerNameMatch(name, searchText));

  const hasExactSuggestionMatch = autocompleteOptions.some(
    (option) => option.name.toLowerCase() === searchText.trim().toLowerCase()
  );

  const directMatchHit = directMatchResult?.[0];

  const shouldShowDirectMatch = directMatchHit && searchText.length >= 3;
  if (shouldShowDirectMatch && isSearchProductHit(directMatchHit)) {
    // delete last item if there are more than max suggestions
    if (autocompleteOptions.length >= MAX_SUGGESTION_COUNT) {
      delete autocompleteOptions[autocompleteOptions.length - 1];
    }

    // build secondary text for direct match autocomplete item
    const supportText = [
      directMatchHit.partners?.map((partner) => partner).join(', '),
      capitalizeAndTranslateEachWordInEntityType(LEARNING_PRODUCTS_ENUM_MAP[directMatchHit.productType ?? '']),
      getTranslatedDifficulty(directMatchHit.productDifficultyLevel ?? ''),
      getTranslatedDurations(PRODUCT_DURATIONS_STRING[directMatchHit.productDuration || '']),
    ]
      .filter(Boolean)
      .join(' • ');

    // prepend direct match
    autocompleteOptions.unshift({
      id: `${AUTOCOMPLETE_SECTION_PREFIXES.DIRECT_MATCHES}${directMatchHit.url}`,
      name: directMatchHit.name ?? '',
      imageUrl: directMatchHit.partnerLogos?.[0] ?? directMatchHit.imageUrl ?? '',
      supportText,
    });
  }

  // replace last item with partner match if one exists
  if (partnerHit && autocompleteOptions.length >= MAX_SUGGESTION_COUNT) {
    autocompleteOptions[autocompleteOptions.length - 1] = partnerHit;
  } else if (partnerHit) {
    autocompleteOptions.push(partnerHit);
  }

  // manually add "see all results" option
  if (searchText.trim() && !hasExactSuggestionMatch) {
    autocompleteOptions.push({
      id: `${AUTOCOMPLETE_SECTION_PREFIXES.SEE_ALL_TEXT}${searchText}`,
      name: _t('See all results for "#{searchText}"', { searchText }),
      textValue: searchText,
    });
  }
  return [
    {
      id: 'search',
      title: '',
      children: autocompleteOptions,
    },
  ];
}

export function createZeroStateAutocompleteItems(trendingResults: SearchResults | undefined): AutocompleteItemGroup[] {
  return [
    {
      id: TRENDING_SEARCHES_GROUP_ID,
      title: _t('Trending topics'),
      children: mapSearchDataToAutocompleteItems(trendingResults?.[0].elements || [], TRENDING_SEARCHES_GROUP_ID),
    },
  ];
}

function getSuggestionSectionAndType(sectionPrefix: string | undefined, displayedQuery: string) {
  let suggestionSection: SuggestionSection = 'suggested_searches';
  let suggestionType: SuggestionType = 'search_query';
  const partnerMatch = isExactPartnerNameMatch(displayedQuery);
  switch (sectionPrefix) {
    case AUTOCOMPLETE_SECTION_PREFIXES.RECENT_SEARCHES:
      suggestionSection = 'recent_searches';
      break;
    case AUTOCOMPLETE_SECTION_PREFIXES.SEE_ALL_TEXT:
      suggestionType = 'all_results_for';
      break;
    case AUTOCOMPLETE_SECTION_PREFIXES.RECENTLY_VIEWED:
      suggestionType = 'product';
      suggestionSection = 'recently_viewed';
      break;
    case AUTOCOMPLETE_SECTION_PREFIXES.TRENDING_SEARCHES:
      suggestionSection = 'trending';
      break;
    case AUTOCOMPLETE_SECTION_PREFIXES.DIRECT_MATCHES:
      suggestionType = 'product';
      suggestionSection = 'direct_matches';
      break;
    default:
      break;
  }
  if (partnerMatch) {
    suggestionType = 'partner';
  }

  return { suggestionSection, suggestionType };
}

function findRecentlyViewedProduct(recentlyViewedItems: AutocompleteItems, displayedQuery: string): Product | null {
  const recentlyViewedItemMatch = recentlyViewedItems.find((item) => item.name === displayedQuery);
  const productId = recentlyViewedItemMatch?.eventProductId?.split(AUTOCOMPLETE_PREFIX_DELIMITER).pop();
  if (productId && recentlyViewedItemMatch) {
    return {
      id: productId,
      name: recentlyViewedItemMatch.name,
      slug: recentlyViewedItemMatch.path,
    };
  }
  return null;
}

function getAssociatedPartner(displayedQuery: string): Partner | null {
  if (isExactPartnerNameMatch(displayedQuery)) {
    const partnerMatch = getTop10PartnerOptions().find(({ name }) => isLoosePartnerNameMatch(name, displayedQuery));
    if (partnerMatch) {
      return { primaryPartnerId: partnerMatch.partnerId };
    }
  }
  return null;
}

function getSuggestionState(autocompleteMode: AutocompleteMode): SuggestionState {
  switch (autocompleteMode) {
    case AUTOCOMPLETE_MODES.AUTOCOMPLETE_ZERO_STATE:
      return 'zero_state';
    case AUTOCOMPLETE_MODES.AUTOCOMPLETE_AS_YOU_TYPE:
      return 'as_you_type';
    case AUTOCOMPLETE_MODES.AUTOCOMPLETE_REPEAT_SEARCH:
      return 'repeat_search';
    default:
      return 'zero_state';
  }
}
export function getAutocompleteEventingData(
  suggestionItem: string,
  autocompleteMode: AutocompleteMode,
  recentlyViewedItems: AutocompleteItems,
  suggestionIndex?: number
) {
  const sectionPrefix = autocompletePrefixes.find((prefix) => suggestionItem.startsWith(prefix));
  const displayedQuery = sectionPrefix ? suggestionItem.replace(sectionPrefix, '') : suggestionItem;
  const searchSuggestions = displayedQuery;

  const { suggestionSection, suggestionType } = getSuggestionSectionAndType(sectionPrefix, displayedQuery);
  const product = findRecentlyViewedProduct(recentlyViewedItems, displayedQuery);
  const associatedPartner = getAssociatedPartner(displayedQuery);
  const suggestionState = getSuggestionState(autocompleteMode);
  const result: ClickAutocompleteSuggestions = {
    suggestionState,
    suggestionSection,
    suggestionType,
    searchSuggestions,
    suggestionIndex,
    page: {},
  };

  if (typeof suggestionIndex !== 'undefined') {
    result.suggestionIndex = suggestionIndex;
  }

  if (associatedPartner) {
    result.associatedPartner = associatedPartner;
  }

  if (product) {
    result.product = product;
  }

  return result;
}
const getAutocompleteLegacyProduct = (hit: Hits | SuggestionHits): Product | null => {
  // for producthit id,name,objectUrl for discocollection its id, name, objectUrl for recentlyViewed, objectId, name, objectUrl
  if (
    (hit.__typename === 'Search_ProductHit' ||
      hit.__typename === 'DiscoveryCollections_professionalCertificate' ||
      hit.__typename === 'DiscoveryCollections_specialization') &&
    hit.id
  ) {
    return {
      id: hit.id,
      name: hit.name,
      slug: hit.objectUrl,
    };
  }
  if (hit.trackingName === 'zero_state_product') {
    return {
      id: hit.objectID || '',
      name: hit.name,
      slug: hit.objectUrl,
    };
  }
  return null;
};

export const getLegacyAutocompleteInfo = (hitSection: string) => {
  let suggestionSection: SuggestionSection;
  let suggestionType: SuggestionType;

  switch (hitSection) {
    case 'most_popular_specializations':
      suggestionSection = 'most_popular_specializations';
      suggestionType = 'product';
      break;

    case 'popular_right_now':
      suggestionSection = 'popular_right_now';
      suggestionType = 'search_query';
      break;

    case 'popular_searches':
      suggestionSection = 'popular_searches';
      suggestionType = 'search_query';
      break;

    case 'recent_searches':
      suggestionSection = 'recent_searches';
      suggestionType = 'search_query';
      break;

    case 'recently_viewed':
      suggestionSection = 'recently_viewed';
      suggestionType = 'product';
      break;

    default:
      suggestionSection = 'suggested_searches';
      suggestionType = 'search_query';
      break;
  }

  return { suggestionSection, suggestionType };
};

export function getAutocompleteLegacyEventingData(hit: Hits | SuggestionHits, query: string) {
  const searchSuggestions = hit.name;
  const product = getAutocompleteLegacyProduct(hit);
  const { suggestionSection, suggestionType } = getLegacyAutocompleteInfo(hit.sectionId || '');

  const result: ClickAutocompleteSuggestions = {
    suggestionState: query ? 'as_you_type' : 'zero_state',
    suggestionSection,
    suggestionType,
    searchSuggestions,
    suggestionIndex: hit.hitPosition,
    page: {},
  };
  if (product) {
    result.product = product;
    result.suggestionType = 'product';
    if (suggestionSection === 'suggested_searches') {
      result.suggestionSection = 'direct_matches';
    }
  }
  return result;
}
