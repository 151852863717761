import _ from 'lodash';

import type { CourseType } from 'bundles/enterprise-legacy-learner-home/utils/courseTypeMetadataUtils';

import _t from 'i18n!nls/enterprise-legacy-learner-home';

const getButtonTextByCourseProgress = (summaryCoreProgressPercentage = 0) => {
  return summaryCoreProgressPercentage === 100 ? _t('Review') : _t('Resume');
};

const getTrackingButtonTextByCourseProgress = (summaryCoreProgressPercentage = 0) => {
  return summaryCoreProgressPercentage === 100 ? 'Review' : 'Resume';
};

const getProgressByLearnerMaterial = (learnerMaterials: $TSFixMe) => {
  return (learnerMaterials && learnerMaterials.summaryCoreProgressPercentage) || 0;
};

const getEnrolledSession = (learnerSessions: $TSFixMe) => {
  return learnerSessions && _.first(_.filter(learnerSessions, (item) => item.isEnrolled));
};

export {
  getButtonTextByCourseProgress,
  getProgressByLearnerMaterial,
  getEnrolledSession,
  getTrackingButtonTextByCourseProgress,
};

export type CourseTypeMetadata = {
  courseTypeMetadata: {
    __typename: string;
  };
};

export function isGuidedProject(courseTypeMetadata: CourseTypeMetadata | null | undefined) {
  return (
    courseTypeMetadata?.courseTypeMetadata.__typename === 'Course_RhymeProjectMetadata' ||
    courseTypeMetadata?.courseTypeMetadata.__typename === 'CourseTypeMetadataV1_rhymeProjectMember'
  );
}

export function isProject(courseTypeMetadata: CourseTypeMetadata | null | undefined) {
  return (
    courseTypeMetadata?.courseTypeMetadata.__typename === 'Course_ProjectMetadata' ||
    courseTypeMetadata?.courseTypeMetadata.__typename === 'CourseTypeMetadataV1_projectMember'
  );
}

export function isProjectOrSelfPacedProject(courseTypeMetadata: CourseTypeMetadata | null | undefined) {
  return isGuidedProject(courseTypeMetadata) || isProject(courseTypeMetadata);
}

export const getCourseType = (courseTypeMetadata: CourseTypeMetadata | null | undefined): CourseType | undefined => {
  switch (courseTypeMetadata?.courseTypeMetadata.__typename) {
    case 'Course_RhymeProjectMetadata':
    case 'CourseTypeMetadataV1_rhymeProjectMember':
      return 'GuidedProject';
    case 'Course_ProjectMetadata':
    case 'CourseTypeMetadataV1_projectMember':
      return 'Project';
    case 'Course_StandardCourseMetadata':
    case 'CourseTypeMetadataV1_standardCourseMember':
      return 'StandardCourse';
    // This type is a basically a shell course with only assessments. Despite the name, it is not related to short term content
    case 'Course_ShortFormContentMetadata':
    case 'CourseTypeMetadataV1_shortFormContentMember':
      return 'Assessment';
    default:
      throw new Error(`typeName of ${courseTypeMetadata?.courseTypeMetadata.__typename} not supported.`);
  }
};
