import { useMemo } from 'react';

// FIXME: existing import/no-cycle violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
// eslint-disable-next-line import/no-cycle
import { useCoachPermissions } from 'bundles/ai-course-coach/utils/CoachPermissionWrapper';
import { getGlobalAppName } from 'bundles/ai-course-coach/utils/getGlobalAppName';
import { isCoachEnabledByApp } from 'bundles/ai-course-coach/utils/isCoachEnabledByApp';
import leeCoachEpicClient from 'bundles/epic/clients/leeCoach';
import leeCoreLexEpicClient from 'bundles/epic/clients/leeCoreLex';
import { createFeatureScope } from 'bundles/product-features';

// Feature flags for Product Features System
// https://coursera.atlassian.net/wiki/spaces/ENT/pages/1327235526/Product+Features+Developer+Guide
export const { ProductFeaturesQuery, useProductFeatures } = createFeatureScope({
  enterprise: {
    enableAiCoach: true /* value is unused */,
    enableAiCoachEarlyAccess: true /* value is unused */,
  },
  degrees: {
    enableAiCoachForDegrees: true /* value is unused */,
  },
});

// Feature flags for EPIC
export const isShowClipRecommendations = () => {
  return leeCoreLexEpicClient.get('showClipRecommendations');
  //   https://tools.coursera.org/epic/experiment/E2xr4cpFEe2TRhJQjI_Svw
};

/**
 * EPIC check to enable FullStory for "Coach superusers".
 *
 * See COACH-539 for more details
 */
export const isCoachSuperuserFullStoryEnabled = (): boolean => {
  return leeCoachEpicClient.get('enableCoachSuperuserFullStory');
};

/**
 * EPIC check to enable Coach Tutor GA
 * https://tools.coursera.org/epic/experiment/ddkxDKNvEe-x6Qr_29vidQ
 */
export const isCoachTutorGAEpic = ({ epicImpression = false }: { epicImpression?: boolean } = {}): boolean => {
  if (epicImpression) {
    return leeCoachEpicClient.get('enableCoachTutor');
  }
  return leeCoachEpicClient.preview('enableCoachTutor');
};

export const useCoachEpicEnabledState = ({ epicImpression }: { epicImpression?: boolean } = {}) => {
  const appName = useMemo(() => getGlobalAppName(), []);
  const coachIncludedByRoute = useMemo(() => isCoachEnabledByApp(appName), [appName]);

  // pass in if there should be an impression triggered in the event the epic is called. default false
  const coachEnabled = useCoachPermissions({ epicImpression });

  return useMemo(
    () => ({
      showEmbeddedCoach: coachIncludedByRoute && coachEnabled.showEmbeddedCoach,
    }),
    [coachEnabled.showEmbeddedCoach, coachIncludedByRoute]
  );
};

export const isCoachAudioEnabled = (): boolean => {
  return leeCoachEpicClient.get('coachAudioEnabled');
};
