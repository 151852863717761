import ar from './ar.json';
import de from './de.json';
import el from './el.json';
import en from './en.json';
import es from './es.json';
import faAf from './fa-af.json';
import fr from './fr.json';
import hi from './hi.json';
import hu from './hu.json';
import id from './id.json';
import it from './it.json';
import ja from './ja.json';
import kk from './kk.json';
import ko from './ko.json';
import nl from './nl.json';
import pl from './pl.json';
import ps from './ps.json';
import pt from './pt.json';
import ru from './ru.json';
import sv from './sv.json';
import th from './th.json';
import tr from './tr.json';
import uk from './uk.json';
import vi from './vi.json';
import zhTw from './zh-tw.json';
import zh from './zh.json';

export default {
  ar: ar,
  de: de,
  el: el,
  en,
  'en-US': en,
  es: es,
  'fa-af': faAf,
  fr: fr,
  hi: hi,
  hu: hu,
  id: id,
  it: it,
  ja: ja,
  kk: kk,
  ko: ko,
  nl: nl,
  pl: pl,
  ps: ps,
  pt: pt,
  ru: ru,
  sv: sv,
  th: th,
  tr: tr,
  uk: uk,
  vi: vi,
  'zh-tw': zhTw,
  'zh-hk': zhTw,
  'zh-mo': zhTw,
  zh: zh,
};
