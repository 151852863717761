/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useState } from 'react';

import cx from 'classnames';

import { Button } from '@coursera/cds-core';
import type { ButtonProps } from '@coursera/cds-core';

import CoachIcon from 'bundles/ai-course-coach/assets/CoachIcon';

const styles = {
  common: (size: 'small' | 'medium') => css`
    /* CDS overrides */
    --cds-color-interactive-stroke-primary-focus: var(--cds-color-emphasis-primary-background-xxstrong);
    --cds-color-interactive-stroke-primary-focus-invert: var(--cds-color-emphasis-primary-background-weak);

    height: ${size === 'small' ? '100%' : '64px'};
    width: ${size === 'small' ? '100%' : '64px'};
    padding: 0 var(--cds-spacing-50);
    border-radius: var(--cds-border-radius-100);
    box-shadow: none !important;
    font-size: 12px;
    gap: var(--cds-spacing-50);

    &:hover,
    &:active {
      background-color: var(--cds-color-emphasis-primary-background-xweak);
    }

    &:focus {
      background-color: var(--cds-color-emphasis-primary-background-xweak);

      &::after {
        border-radius: var(--cds-border-radius-100) !important;
      }
    }

    &:active {
      border-radius: 12px;
      transition: all 0.2s cubic-bezier(0, 1, 0.9, 1);
      transform: scale3d(0.98, 0.98, 0.98);
    }

    .cds-button-label {
      color: var(--cds-color-emphasis-neutral-background-xxstrong);
      display: flex;
      flex-direction: column-reverse;
      margin-top: 4px;
    }

    .cds-button-endIcon {
      margin: 0;
    }

    &.selected {
      .cds-button-label {
        color: var(--cds-color-blue-600);
      }

      .cds-button-endIcon {
        svg {
          fill: var(--cds-color-blue-600);
        }
      }

      background-color: var(--cds-color-emphasis-primary-background-xweak);
    }

    &.cds-button-disabled {
      .cds-button-label {
        color: var(--cds-color-grey-100);
      }

      background-color: transparent;
    }
  `,
  toolbarButton: css`
    &.cds-button-disabled {
      .cds-button-endIcon {
        svg path {
          fill: var(--cds-color-grey-100);
        }
      }
    }

    .cds-button-endIcon {
      svg {
        width: 20px;
        height: 20px;
      }
    }
  `,
};

type Props = {
  selected?: boolean;
  onClick?: () => void;
} & ButtonProps;

// TODO: Move ToolbarButton.tsx to live with the Toolbar code once it is built

/**
 * Custom toolbar button component for Embedded Coach in LEx project
 */
export const ToolbarButton = (props: Props): React.ReactElement => {
  const { children, icon, selected = false, size = 'medium', onClick, ...rest } = props;

  return (
    <Button
      {...rest}
      className={cx({ selected })}
      css={[styles.common(size), styles.toolbarButton]}
      variant="secondary"
      icon={icon}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

/**
 * Custom Coach toolbar button component for Embedded Coach in LEx project
 * Similar to the ToolbarButton component but with winking Coach icon
 */
export const CoachToolbarButton = (props: Props): React.ReactElement => {
  const { children, selected = false, disabled, size = 'medium', onClick, ...rest } = props;
  const [isCoachWinking, setIsCoachWinking] = useState(false);

  const handleMouseEnter = () => {
    setIsCoachWinking(true);
  };

  const handleMouseLeave = () => {
    setIsCoachWinking(false);
  };

  const handleFocus = () => {
    setIsCoachWinking(true);
  };

  const handleBlur = () => {
    setIsCoachWinking(false);
  };

  const handleOnClick = () => {
    if (onClick) {
      onClick();
    }
    setIsCoachWinking(false);
  };

  let iconToRender;
  if (isCoachWinking) {
    iconToRender = <CoachIcon state="wink" size={size} />;
  } else if (disabled) {
    iconToRender = <CoachIcon state="disabled" size={size} />;
  } else {
    iconToRender = <CoachIcon size={size} />;
  }

  return (
    <Button
      {...rest}
      disabled={disabled}
      className={cx({ selected })}
      css={styles.common(size)}
      variant="secondary"
      icon={iconToRender}
      {...(!disabled && {
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave,
        onFocus: handleFocus,
        onBlur: handleBlur,
        onClick: handleOnClick,
      })}
    >
      {children}
    </Button>
  );
};
