import { useQuery } from '@apollo/client';

import { ProductFeatureSet } from './classes';
import { productFeaturesQuery } from './queries';
import type { ProductFeaturesQueryData, ProductFeaturesQueryVariables, ProductFeaturesRequest, Scope } from './types';
import { scopeToFeatureIdentifications } from './utils';

type UseProductFeaturesProps = ProductFeaturesRequest & { skip?: boolean };

export const createProductFeaturesHook = <S extends Scope<{}>>(scope: S) => {
  const featureIdentifications = scopeToFeatureIdentifications(scope);
  const useProductFeatures = ({ idType, id, skip, ssr }: UseProductFeaturesProps) => {
    const { data, loading, error } = useQuery<ProductFeaturesQueryData, ProductFeaturesQueryVariables>(
      productFeaturesQuery,
      {
        ssr,
        variables: { input: { idType, id, featureIdentifications } },
        skip: !!skip || !id,
      }
    );

    return {
      loading,
      error,
      features: ProductFeatureSet.createWithMemoize<S>(data?.GetConfiguredFeatures),
    };
  };
  return useProductFeatures;
};
