/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { branch, compose } from 'recompose';

import createLoadableComponent from 'js/lib/createLoadableComponent';
import { useLocation } from 'js/lib/useRouter';
import { isCourserian } from 'js/lib/user';

import { isIntegrityPortalEnabled } from 'bundles/academic-integrity/lib/epics';
import GroupsDeprecationModal from 'bundles/admin-v2/components/GroupsDeprecationModal';
import type AdminPermission from 'bundles/admin/utils/AdminPermission';
import { ExternalCoachLauncher } from 'bundles/ai-course-coach/components/Launcher';
import { useCoachEpicEnabledState } from 'bundles/ai-course-coach/utils/productFeatureFlags';
import withManagedOrganizations from 'bundles/enterprise-admin-navigation/utils/withManagedOrganizations';
import leeCoachEpicClient from 'bundles/epic/clients/leeCoach';
// loading directly to avoid lazy loading complexity
import { withCheatingIncidentsByUserIdAndCourseIdsGraphql } from 'bundles/integrity-portal/components/api/CheatingIncidentsByUserIdAndCourseIds';
import type EnterpriseProgramsV1 from 'bundles/naptimejs/resources/enterprisePrograms.v1';
import type ThirdPartyOrganizationsV1 from 'bundles/naptimejs/resources/thirdPartyOrganizations.v1';
import ClientSideRenderedNotificationCenter from 'bundles/notification-center/components/ClientSideRenderedNotificationCenter';
import AdminRightNavButtons from 'bundles/page-header/components/AdminRightNavButtons';
import AuthenticatedAccountDropdown from 'bundles/page-header/components/AuthenticatedAccountDropdown';
import PageNavigationL1 from 'bundles/page-header/components/page-navigation/PageNavigationL1';
import PageHeaderContext from 'bundles/page-header/contexts/PageHeaderContext';

const LoadableShoppingCart = createLoadableComponent(() => import('bundles/page-header/components/ShoppingCart'));
const LoadableTranslationDropdown = createLoadableComponent(
  () => import('bundles/page-header/components/translation/TranslationDropdown')
);
const LoadableCoachStudio = createLoadableComponent(() => import('bundles/ai-coach-studio/index'));

type PropsFromCaller = {
  className: string;
  userId: number;
  isStaff: boolean;
  showShoppingCart: boolean;
  hideEnterprise?: boolean;
  hideNotificationCenter: boolean;
  handleProgramSwitcherToggle?: () => void;
  programs: Array<EnterpriseProgramsV1>;
  degrees: Array<Record<string, string>>;
  thirdPartyOrganizations?: Array<ThirdPartyOrganizationsV1>;
  showAdminLinks?: boolean;
  thirdPartyOrganizationId: string;
  adminPermission?: AdminPermission;
  hasCheatingIncidents?: boolean;
  courseId?: string;
  showLanguagesDropdown?: boolean;
  itemId?: string;
  showUnifiedLinks?: boolean;
  hideDropdownOptions?: boolean;
};

type PropsToComponent = PropsFromCaller & {
  thirdPartyOrganizations?: Array<ThirdPartyOrganizationsV1>;
};

const styles = {
  rightNav: css`
    /* !important is needed to override the existing styles */
    padding: 0 !important;
    display: flex !important;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    > li {
      float: none !important;

      :hover {
        /* this is to match the existing hover state color */
        background-color: #f0f0f0;
      }
    }
  `,
};

export const AuthenticatedAccountInfo: React.FC<PropsToComponent> = ({
  isStaff = false,
  degrees,
  programs,
  thirdPartyOrganizations,
  showShoppingCart,
  hideNotificationCenter,
  hideEnterprise,
  showAdminLinks,
  className,
  thirdPartyOrganizationId,
  hasCheatingIncidents,
  showLanguagesDropdown,
  courseId,
  showUnifiedLinks,
  hideDropdownOptions,
  adminPermission,
}) => {
  const [isMounted, setIsMounted] = React.useState(false);

  const showCoachStudio = leeCoachEpicClient.preview('coachStudioEnabled') && isCourserian();
  // including Only in course pages on the  / learn route from showing the coach in global header. this will eventually go away after the AB test
  const { showEmbeddedCoach } = useCoachEpicEnabledState();

  React.useEffect(() => setIsMounted(true), []);

  const [isGroupsModalOpen, setIsGroupsModalOpen] = React.useState(false);
  const location = useLocation();

  const handleGroupsClick = () => setIsGroupsModalOpen(true);

  const closeGroupsModal = () => setIsGroupsModalOpen(false);

  const dropdownProps = {
    isStaff,
    degrees,
    programs,
    thirdPartyOrganizations,
    thirdPartyOrganizationId,
    showAdminLinks: !!showAdminLinks,
    hasCheatingIncidents,
    hideDropdownOptions,
  };

  // legacy backbone pages do not have router in context
  let hideAvatarBorder: boolean;

  hideAvatarBorder = location.pathname.includes('enterprise') || location.pathname.includes('programs');

  hideAvatarBorder = Boolean(hideAvatarBorder || hideEnterprise);

  return (
    <PageHeaderContext.Consumer>
      {({ isSimplifiedPageHeader }) => (
        <React.Fragment>
          <ul
            id="authenticated-info-menu"
            className={className}
            role="menu"
            css={isSimplifiedPageHeader && styles.rightNav}
          >
            {isMounted && (
              <AdminRightNavButtons
                showAdminLinks={showAdminLinks}
                adminPermission={adminPermission}
                handleGroupsClick={handleGroupsClick}
              />
            )}
            {showUnifiedLinks && <PageNavigationL1 />}

            {showShoppingCart && (
              <LoadableShoppingCart
                className="c-ph-right-nav-button"
                hideAvatarBorder={hideAvatarBorder || isSimplifiedPageHeader}
              />
            )}
            {
              // All unified pages should show the translation dropdown unless explicitiy hidden with showLanguagesDropdown={false}
              (showLanguagesDropdown ?? isSimplifiedPageHeader) && (
                <LoadableTranslationDropdown courseId={courseId} enterpriseOrgId={thirdPartyOrganizationId} />
              )
            }
            {!hideNotificationCenter && (
              <li role="none">
                <ClientSideRenderedNotificationCenter />
              </li>
            )}

            {showEmbeddedCoach && (
              <li role="none">
                <ExternalCoachLauncher />
              </li>
            )}
            {!showEmbeddedCoach && showCoachStudio && (
              <li role="none">
                <LoadableCoachStudio />
              </li>
            )}
            <AuthenticatedAccountDropdown
              {...dropdownProps}
              hideAvatarBorder={Boolean(hideAvatarBorder || isSimplifiedPageHeader)}
              label=""
            />
          </ul>
          {isGroupsModalOpen && <GroupsDeprecationModal onClose={closeGroupsModal} />}
        </React.Fragment>
      )}
    </PageHeaderContext.Consumer>
  );
};

const AuthenticatedAccountInfoHOC = compose<PropsToComponent, PropsFromCaller>(
  branch(() => typeof window !== 'undefined', withManagedOrganizations),
  branch(() => isIntegrityPortalEnabled(), withCheatingIncidentsByUserIdAndCourseIdsGraphql)
)(AuthenticatedAccountInfo);

export default AuthenticatedAccountInfoHOC;
